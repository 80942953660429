<template>
  <div>
    <div class="d-flex justify-content-end align-item-center flex-wrap mb-2 px-2">
      <b-form-group
        label=""
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <b-table
      ref="selectableTable"
      striped
      hover
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(#)="{ rowSelected }">
        <template v-if="rowSelected">
          <feather-icon
            icon="CheckCircleIcon"
            style="color: #28c76f"
            size="14"
          />
        </template>

        <template v-else>
          <feather-icon
            icon="CircleIcon"
            size="14"
          />
        </template>
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <!--  Actual Pay -->
      <template #cell(actual_pay)="row">
        <span v-if="row.item.actual_pay">
          ${{ row.item.actual_pay.toFixed(2) }}
        </span>
      </template>

      <!--  Estimated Cost -->
      <template #cell(estimated_cost)="row">
        <span v-if="row.item.estimated_cost">
          ${{ row.item.estimated_cost.toFixed(2) }}
        </span>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      perPage: 30,
      pageOptions: [20, 30, 40],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        '#',
        { key: 'invoice_number', label: 'Invoice ID' },
        { key: 'block_id', label: 'Block ID' },
        { key: 'trip_id', label: 'Trip ID', sortable: true },
        { key: 'load_id', label: 'Load ID', sortable: true },
        { key: 'actual_pay', label: 'Actual Pay', sortable: true },
        { key: 'estimated_cost', label: 'Estimated Cost', sortable: true },
        // { key: 'balance', label: 'Balance', sortable: true },
      ],
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>

</style>
